export interface IInvestmentManager {
  about?: string;
  address?: string;
  address2?: string;
  authorizedSignatories?: string;
  code?: string;
  collaborate?: boolean;
  country?: number;
  createdAt?: Date | null;
  email?: string;
  fax?: string;
  id?: number;
  isActive?: boolean;
  logoPath?: string;
  managementFee?: string;
  name?: string;
  npwp?: string;
  ownership?: string;
  phone?: string;
  postalCode?: string;
  province?: number;
  regency?: number;
  scanOps?: string;
  scanPks?: string;
  updatedAt?: Date | null;
  websiteURL?: string;
  totalManagedFunds?: number;
}

export const defaultValue: Readonly<IInvestmentManager> = {
  isActive: false
};
