import './confirmation.scss';

import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Spinner } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IConfirmation, IConfirmationPostBody } from 'app/shared/model/confirmation.model';
import { postConfirmation } from './confirmation.reducer';

export const ConfirmationHead = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();
  const [code, setCode] = useState<string>("")
  const [status, setStatus] = useState<string>("")
  const [otpKey, setOtpKey] = useState<string>("")

  // const account = useAppSelector(state => state.authentication.account);
  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    if(params.get('code') && params.get('status')) {
      setCode(encodeURIComponent(params.get('code')));
      setStatus(params.get('status'));
      if(params.get('status') === 'approve' || params.get('status') === 'reject') {
        const postData: IConfirmationPostBody = {
          code: encodeURIComponent(params.get('code')),
          status: params.get('status'),
          message: ''
        }
        dispatch(postConfirmation(postData));
      } else {
        navigate('/confirmation-invalid')
      }
    }
  }, [pageLocation.search]);

  const confirmData: IConfirmation = useAppSelector(state => state.confirmation.confirmation);

  if (confirmData.status === 'EXPIRED_LINK') {
    return <Navigate to={'/confirmation-head-expired'} replace />;
  }
  if (confirmData.status === 'INVALID_LINK') {
    return <Navigate to={'/confirmation-invalid'} replace />;
  }
  if (confirmData.status === 'APPROVED') {
    return <Navigate to={'/confirmation-approve'} replace />;
  }
  if (confirmData.status === 'REJECTED') {
    return <Navigate to={'/confirmation-reject'} replace />;
  }
  
  return (
    <div>
      <div style={{
        backgroundColor:'#F2F3F5',
        width: '100vw',
        height: '100vh'
      }} className="d-flex align-items-center justify-content-center flex-column">
        <Spinner animation="border" role="status" color="danger" />
      </div>
    </div>
  );
};

export default ConfirmationHead;
