import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import axios from 'axios';
import { defaultValue, defaultValueSearchProducts, IProduct, IProductTable } from 'app/shared/model/product.model';

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IProduct>,
  productSub: defaultValue,
  productRed: defaultValue,
  totalItems: 0,
  search: defaultValueSearchProducts
};

const apiUrl = 'services/mfproductservice/api/v1/products';
const apiUrlInstitution = 'services/mfproductservice/api/v1/institution/products';
const apiUrlEntity = 'services/mfproductservice/api/v1/product/view';

// Async Actions
export const getEntities = createAsyncThunk('product/fetch_products', async (search: IProductTable) => {
  const requestUrl = `${apiUrl}${search.sort ? `?page=${search.page}&size=${search.size}&sort=${search.sort}` : ''}`;
  return axios.get<IProduct[]>(requestUrl);
});

export const getEntitiesInstitution = createAsyncThunk('product/fetch_products', async (search: IProductTable) => {
  const requestUrl = `${apiUrlInstitution}${search.sort ? `?page=${search.page}&size=${search.size}&sort=${search.sort}` : ''}`;
  return axios.get<IProduct[]>(requestUrl);
});

export const getEntity = createAsyncThunk('product/fetch_product', async (code: string) => {
  const requestUrl = `${apiUrlEntity}/${code}`;
  return axios.get<IProduct>(requestUrl);
});

export const updateSearchParameters = (search: IProductTable)  => dispatch => {
  dispatch(searchChange(search));
};

export const setProductSub = (product: IProduct)  => dispatch => {
  dispatch(setSub(product));
};

export const resetProductSub = ()  => dispatch => {
  dispatch(resetSub());
};

export const setProductRed = (product: IProduct)  => dispatch => {
  dispatch(setRed(product));
};

export const resetProductRed = ()  => dispatch => {
  dispatch(resetRed());
};

export type ProductState = Readonly<typeof initialState>;

export const ProductSlice = createSlice({
  name: 'product',
  initialState: initialState as ProductState,
  reducers: {
    reset() {
      return initialState;
    },
    searchChange(state, action) {
      return {
        ...state,
        search: action.payload,
      };
    },
    setSub(state, action) {
      return {
        ...state,
        productSub: action.payload,
      };
    },
    resetSub(state) {
      return {
        ...state,
        productSub: defaultValue,
      };
    },
    setRed(state, action) {
      return {
        ...state,
        productRed: action.payload,
      };
    },
    resetRed(state) {
      return {
        ...state,
        productRed: defaultValue,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getEntities, getEntitiesInstitution), (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addMatcher(isFulfilled(getEntity), (state, action) => {
        state.loading = false;
        state.productSub = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addMatcher(isPending(getEntities, getEntitiesInstitution, getEntity), state => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isRejected(getEntities, getEntitiesInstitution, getEntity), (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      });
  },
});

export const { reset, searchChange, setSub, resetSub, setRed, resetRed } = ProductSlice.actions;

// Reducer
export default ProductSlice.reducer;
