import './confirmation.scss';

import React from 'react';
import { Col, Row } from 'reactstrap';

import { Card, Grid, Typography } from '@mui/material';

export const ConfirmationReject = () => {
  return (
    <>
      <div style={{
        backgroundColor:'#F2F3F5',
        width: '100vw',
        height: '100vh'
      }} className="d-flex align-items-center justify-content-center flex-column">
        <Grid container spacing={4} sx={{
            textAlign: 'center',
            maxWidth: '500px'
          }}>
          <Grid item xs={12} sx={{
            textAlign: 'center'
          }}>
            <img src="https://navi.miraeasset.co.id/img/logo.png" width="113"></img>
          </Grid>
          <Grid item xs={12} sx={{
            textAlign: 'center',
            maxWidth: '467px'
          }}>
            <Card sx={{
              padding: '40px'
            }}>
              <Row>
                <Col>
                  <span className="icon-reject mb-4" />
                </Col>
              </Row>
              <Row className='mb-2'>
                <Col>
                  <Typography sx={{
                    fontSize: '24px',
                    fontWeight: '700',
                    lineHeight: '32px',
                    textAlign: 'center',
                    color: '#19191A'
                  }}>
                    Transaksi ini berhasil dibatalkan
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Typography sx={{
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    textAlign: 'center',
                    color: '#636566'
                  }}>
                    Anda telah berhasil membatalkan transaksi ini. Terima kasih.
                  </Typography>
                </Col>
              </Row>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ConfirmationReject;
