import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AUTHORITIES } from 'app/config/constants';
import { useAppSelector } from 'app/config/store';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import * as React from 'react';
import { BiTransferAlt } from "react-icons/bi";
import { FaHome } from "react-icons/fa";
import { IoMdDoneAll } from "react-icons/io";
import { MdFaceRetouchingNatural, MdOutlineAssignmentInd, MdOutlineDiversity3, MdOutlineScheduleSend, MdTransferWithinAStation } from "react-icons/md";
import { TbHistoryToggle } from "react-icons/tb";
import { translate } from 'react-jhipster';
import { Link, useLocation } from 'react-router-dom';

const menuNonAdmin = [
  // {
  //   id: 'Entities',
  //   children: [
  //     { id: 'Relationship Manager', icon: <MdOutlineAssignmentInd />, href: '/rm' },
  //     { id: 'Customer', icon: <MdFaceRetouchingNatural />, href: '/customer' },
  //     { id: 'Customer Transfer', icon: <BiTransferAlt />, href: '/customer/transfer' },
  //   ],
  // },
  // {
  //   id: 'Transaction',
  //   children: [
  //     { id: 'Draft', icon: <TbHistoryToggle />, href: '/history' },
  //     { id: 'Approval', icon: <IoMdDoneAll />, href: '/approval' },
  //     { id: 'Send Customer Action', icon: <MdOutlineScheduleSend />, href: '/customeraction' },
  //   ],
  // },
];

const menuAdmin = [
  {
    id: 'User Management',
    children: [
      { id: 'Employee', icon: <MdOutlineDiversity3 />, href: '/admin/user-management' },
      // { id: 'Employee Transfer', icon: <MdTransferWithinAStation />, href: '/admin/user-transfer' },
    ],
  },
];

const item = {
  py: '4px',
  px: 3,
  color: '#fff',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

export const ListMenu = () => {
  
  const pageLocation = useLocation();
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const isDealer = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.DEALER]));
  const isRM = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.RM]));
  const isHeadDO1 = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.HEAD_DO1]));
  const isHeadDO2 = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.HEAD_DO2]));
  
  return (
    <List disablePadding>
        <Box key='logo' sx={{ ...itemCategory, fontSize: 22, color: '#FFFFFF' }}>
          <div className="brand-logo center">
            <div className='brand-icon3'>
              <img src="https://navi.miraeasset.co.id/favicon.png" alt="Logo" width={40} height={40} />
            </div>
          </div>
        </Box>
        <Link style={{ textDecoration: 'none' }} to={'/home'}>
          <ListItemButton key='home' sx={{ ...item, ...itemCategory }}
          selected={pageLocation.pathname.includes('/home')}>
            <ListItemIcon>
              <FaHome />
            </ListItemIcon>
            <ListItemText>Dashboard</ListItemText>
          </ListItemButton>
        </Link>
        {/* <Link style={{ textDecoration: 'none' }} to={'/rm'}>
          <ListItemButton sx={{ ...item, ...itemCategory }}
          selected={pageLocation.pathname.includes('/rm')} href={'/rm'}>
          <ListItemIcon>
            <img src="content/images/Frame-2608531.png" alt="Relationship Manager" width={'28'} height={'28'} />
          </ListItemIcon>
          <ListItemText>Relationship Manager</ListItemText>
          </ListItemButton>
        </Link>
        <Link style={{ textDecoration: 'none' }} to={'/customer/123/?page=1&sort=sgKey,asc,asc'}>
          <ListItemButton sx={{ ...item, ...itemCategory }}
          selected={pageLocation.pathname.includes('/customer')} href={'/customer'}>
          <ListItemIcon>
            <img src="content/images/Frame-2608531.png" alt="Customer" width={'28'} height={'28'} />
          </ListItemIcon>
          <ListItemText>Customer</ListItemText>
          </ListItemButton>
        </Link> */}
        <Box>
          <ListItem key={`listCategories-${Math.random()}`} sx={{ py: 1, px: 3 }}>
            <ListItemText sx={{ color: '#FFFFFF' }}>Entities</ListItemText>
          </ListItem>
          {(!isRM || isAdmin || isHeadDO2 || isDealer || isHeadDO1) && ( // hide jika login sebagai RM
          <Link style={{ textDecoration: 'none' }} to={'/rm'}>
            <ListItem disablePadding>
              <ListItemButton selected={pageLocation.pathname === '/rm'} sx={item}>
                <ListItemIcon><MdOutlineAssignmentInd /></ListItemIcon>
                <ListItemText>{translate('rm.home.title')}</ListItemText>
              </ListItemButton>
            </ListItem>
          </Link>
          )}
          <Link style={{ textDecoration: 'none' }} to={'/customer'}>
            <ListItem disablePadding>
              <ListItemButton selected={pageLocation.pathname.includes('/customer')} sx={item}>
                <ListItemIcon><MdFaceRetouchingNatural /></ListItemIcon>
                <ListItemText>{translate('customer.home.title')}</ListItemText>
              </ListItemButton>
            </ListItem>
          </Link>
          <Divider sx={{ mt: 2 }} />
        </Box>
        {menuNonAdmin.map(({ id, children }) => (
          <Box key={id}>
            <ListItem key={`listCategories-${Math.random()}`} sx={{ py: 1, px: 3 }}>
              <ListItemText sx={{ color: '#FFFFFF' }}>{id}</ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, href }) => (
              // eslint-disable-next-line react/jsx-key
              <Link style={{ textDecoration: 'none' }} key={`linkCategories-child-${Math.random()}`} to={href}>
                <ListItem key={`listCategories-child-${Math.random()}`} disablePadding>
                  <ListItemButton selected={pageLocation.pathname === href} sx={item}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText>{childId}</ListItemText>
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
        {isAdmin && menuAdmin.map(({ id, children }) => (
          <Box key={id}>
            <ListItem key={`listItem-${Math.random()}`} sx={{ py: 1, px: 3 }}>
              <ListItemText sx={{ color: '#FFFFFF' }}>{id}</ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, href }) => (
              // eslint-disable-next-line react/jsx-key
              <Link style={{ textDecoration: 'none' }} key={`link-adm-child-${Math.random()}`} to={href}>
                <ListItem key={`listItem-adm-child-${id}`} disablePadding>
                  <ListItemButton selected={pageLocation.pathname === href} sx={item}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText>{childId}</ListItemText>
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
    </List>
  );
};


export default ListMenu;