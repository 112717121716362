export interface IConfirmation {
    data?: IConfirmationData;
    token?: string;
    status?: string;
}

export const defaultValue: Readonly<IConfirmation> = {
    data: null,
    token: '',
    status: null
};

export interface IConfirmationData {
    id?: number;
    orderId?: string;
    paymentMethod?: string;
    totalItem?: string;
    totalTrxFeeAmount?: number;
    totalAmount?: number;
    amountUniqueNumber?: number;
    totalOrderAmount?: number;
    totalPaymentFee?: number;
    referral?: string;
    isAutoDebet?: boolean;
    message?: string;
    tag?: string;
    client?: string;
    ifua?: string;
    sid?: string;
    gerai?: number;
    submittedAt?: Date | null;
    submittedBy?: string;
    internallyApprovedAt?: Date | null;
    internallyApprovedBy?: string;
    reviewedAt?: Date | null;
    reviewedBy?: string;
    status?: string;
    paymentDate?: Date | null;
    batch?: string;
    autoDebetInstruction?: string;
    investorCif?: string;
    investorEmail?: string;
    investorName?: string;
    investorIdentityNo?: string;
    investorIfua?: string;
    geraiName?: string;
    bankAccountTheBank?: number;
    bankAccountBranch?: string;
    bankAccountCurrency?: string;
    bankAccountNo?: string;
    bankAccountName?: string;
    bankSwiftCode?: string;
    bankName?: string;
    schedulerStatus?: string;
    trxStatus?: string;
    invoiceNo?: string;
    transferStatus?: string;
    transferPath?: string;
    isPwm?: boolean;
    eMeteraiId?: number;
    eMeteraiFee?: number;
    sameTimeOrder?: boolean;
    sameTimeOrderReference?: string;
    autoSubsFrequency?: string;
    autoSubsDay?: string;
    autoSubscriptionId?: string;
    clientIp?: string;
    clientOsName?: string;
    orderItems?: string;
    corporate?: boolean;
}

export interface IConfirmationPostBody {
    status?: string;
    message?: string;
    token?: string;
    code?: string;
}