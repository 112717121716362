export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN_DO',
  DEALER: 'ROLE_DEALER',
  RM: 'ROLE_RM',
  HEAD_DO1: 'ROLE_HEAD_DO1',
  HEAD_DO2: 'ROLE_HEAD_DO2'
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATEFULL_FORMAT = 'DD MMMM YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';

export const DRAWER_WIDTH = 256;
