import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import axios from 'axios';

import { serializeAxiosErrorTitle } from 'app/shared/reducers/reducer.utils';

import { defaultValue, IConfirmation, IConfirmationPostBody } from 'app/shared/model/confirmation.model';

const initialState = {
  loading: false,
  errorMessage: null,
  successMessage: null,
  confirmation: defaultValue
};

const apiUrl = 'services/mftransactionservice/api/v2/transaction/confirmation-email/pwm';

// Async Actions

export const getConfirmation = createAsyncThunk('confirmation/fetch_confirmation', async (code :string) => {
  return axios.get<IConfirmation>(`${apiUrl}?code=${code}`);
});

export const postConfirmation = createAsyncThunk(
  'confirmation/post_confirmation',
  // async (postBody: IConfirmationPostBody) => axios.post<IConfirmation>(`${apiUrl}`, postBody),
  async (postBody: IConfirmationPostBody) => axios.post<IConfirmation>(`${apiUrl}`, postBody),
  { serializeError: serializeAxiosErrorTitle },
);

export type ConfirmationState = Readonly<typeof initialState>;

export const ConfirmationSlice = createSlice({
  name: 'confirmation',
  initialState: initialState as ConfirmationState,
  reducers: {
    reset() {
      return initialState;
    },
  }, 

  extraReducers(builder) {
    builder
      .addMatcher(isPending(postConfirmation), state => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isRejected(postConfirmation), (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addMatcher(isFulfilled(postConfirmation), (state, action) => {
        state.loading = false;
        if(action.payload.data.status === 'APPROVED') {
          state.successMessage = 'confirmation.messages.success';
          state.confirmation = action.payload.data;
        } else if(action.payload.data.status === 'INVALID_TOKEN') {
          state.errorMessage = 'confirmation.messages.invalidToken';
        } else {
          state.confirmation = action.payload.data;
        }
      })
      .addMatcher(isPending(getConfirmation), state => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isRejected(getConfirmation), (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addMatcher(isFulfilled(getConfirmation), (state, action) => {
        state.loading = false;
        state.confirmation = action.payload.data;
      });
  },
});

export const { reset } = ConfirmationSlice.actions;

// Reducer
export default ConfirmationSlice.reducer;
